<template>
  <v-app style="padding-bottom: 10px">
    <v-main>
          <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
